
body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
}

.title{
    font-size: 1.6rem;
    letter-spacing: 4px;
    font-family: 'Roboto', sans-serif;
    color: white;
}

/* Header */
.navbar-wrap{
    margin: 0px 0px;
    position: relative;
}

.logo{
    max-width: 270px;
    margin-left: 60px;
}

.items{
    font-weight: 700;
}

#basic-navbar-nav .item{
    margin: 10px 20px 10px 19px;
    font-size: 20px;
    color: #29928d;
}

#basic-nav-dropdown{
    color: #29928d;
}

@media screen and (min-width: 992px) {
    .items {
        position: absolute;
        right: 20px;
    }
}

@media screen and (max-width: 768px){
    .logo{
        margin-left: 30px;
    }
}

@media screen and (max-width: 576px){
    .logo{
        margin-left: 0px;
    }

    #basic-navbar-nav .item{
        font-size: 1.1rem;
    }
}


/* Footer */
.footer{
    margin: 0px auto;
    text-align: center;
    padding: 8px 0px 4px 0px;
    background-color: #e6911c;
}

.footer p{
    color: white;
    font-size: 15px;
    margin: 1px 0px;
}

/* /////////////////////////////////////////////// */

/* Home Page */
.page-wrap{
    background-color: #f1f1f1;
    width: 100vw;
    height: 100%;
    padding: 0px 0px 30px 0px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    max-width: 1400px;
    margin: 0 auto;
}

.homepage-jumbotron{
    background-image: url('https://aaadanyc.com/images/cover.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    padding-top: 0px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    height: 600px;
}

.jumbotron-content {
    background-color: #29928da9;
    border-radius: 20px;
    color: white;
    font-size: 3.4rem;
    margin: 80px 0px 70px 800px;
    padding: 20px 0px;
    letter-spacing: 0.3rem;
}

.jumbotron-content p{
    margin: 0px 0px;
    padding: 0px 0px;
}

.intro{
    margin: 20px 10px 40px 10px;
}

.intro h3{
    margin: 10px 0px 15px 0px;
    font-size: 3rem;
    font-weight: bold;
    text-align: left;
    color: #1d6566;
}

.intro strong{
    color: #1d6566;
}

.intro p{
    line-height: 1.5em;
    letter-spacing: 1px;
    text-align: left;
    font-size: 18px;
    color: #1d6566;
}

.quicklink-wrap{
    width: 220px;
    height: 230px;
    background-color: #29928d;
    border-radius: 25px;
    margin-top: 80px;
    margin-left: 40px;
    padding-top: 30px;
    color: white;
    font-size: 16px;
}

.quicklink-wrap .quicklink{
    padding-top: 8px;
}

.quicklink-wrap h5{
    font-weight: bold;
}

.border{
    width: 180px;
    border: 2px solid white;
    margin-left: 20px;
}

.quicklink{
    width: 180px;
    height: 40px;
    background-color: #e4891b;
    margin-top: 20px;
    margin-left: 20px;
    padding-top: 6px;
}

.quicklink-wrap a{
    text-decoration: none;
    color: white;
    text-align: center;  
}

.quicklink-wrap a:hover{
    font-size: 18px;
}

@media screen and (max-width: 768px){
    .homepage-jumbotron{
        height: 450px;
    }

    .jumbotron-content {
        margin: 80px 0px 0px 380px;
        font-size: 2.4rem;
        width: 280px;
        padding: 30px 0px;
    }

    .quicklink-wrap {
        margin: 90px 50px 0px -10px;  
    }

    .intro{
        margin: 20px 10px 40px -60px;
    }
}

@media screen and (max-width: 576px) {
    .homepage-jumbotron{
        margin-bottom: 20px;
        height: 215px;
    }
    
    .jumbotron-content {
        margin: 20px -40px 100px 170px;
        font-size: 1.1rem;
        width: 120px;
    }

    .intro{
        margin: 20px 10px 40px 10px;
    }

    .intro h3{
        font-size: 1.8rem;
    }

    .quicklink-wrap {
        margin: 20px auto;  
    }
}

@media screen and (max-width: 325px){
    .homepage-jumbotron{
        height: 200px;
    }

    .jumbotron-content {
        margin: 20px -30px 100px 140px;
        font-size: 1.1rem;
        width: 120px;
    }
}


/* About Us */
.aboutus-page-title{
    text-align: left;
    margin: 30px 0px 0px 0px;
    color: #1d6566;
    font-size: 2.3rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
}

.card{
    width: 80rem;
    color: #1d6566;
    margin: 30px 0px;
}

.aboutus-img{
    margin: 40px 20px 40px 80px;
}

.aboutus-article{
    margin: 20px -50px 10px 140px;
}

.aboutus-article-title{
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
}

.aboutus-article-cotent{
    font-size: 1.2rem;
    text-align: left;
    padding-bottom: 10px;
    text-indent: 2rem;
}

@media screen and (max-width: 768px){
    .aboutus-page-title{
        font-size: 2.3rem;
    }

    .card{
        width: auto;
        margin: 10px 0px 20px 0px;
    }

    .aboutus-img{
        margin: 30px 20px 10px 60px;
    }

    .aboutus-article{
        margin: 0px -10px 0px 90px;
    }
}

@media screen and (max-width: 576px) {
    .aboutus-page-title{
        font-size: 1.8rem;
    }

    .card{
        width: auto;
        margin: 10px 0px 20px 0px;
    }

    .aboutus-img{
        width: 240px;
        margin: 30px 20px 10px 20px;
    }

    .aboutus-article{
        margin: 10px 20px;
    }

    .aboutus-article-title{
        font-size: 1.6rem;
    }

    .aboutus-article-cotent{
        font-size: 1rem;
    }
}

.contactus-mid-container{
    margin: 40px auto;
}

.aboutus-article-cotent li{
    margin-top: 10px;
    letter-spacing: 0.5px;
}


/* Events */
.events-wrap{
    margin-top: 0px;
}

.events-wrap h1{
    text-align: start;
    margin: 0px 0px 30px 0px;
    color: #1d6566;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding-top: 30px;
}

.events-title{
    color: #1d6566;
    letter-spacing: 0.3rem;
    margin-top: 10px;
}

.events-title .events-data{
    text-align: center;
    font-size: 1.1rem;
}

.events-img{
    margin: 15px 0px 30px 0px;
    padding: 0px 100px;
}

.events-wrap p{
    margin: 10px 100px 0px 100px;
    text-align: start;
    text-indent: 2rem;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    color: #1d6566;
}

.event-border{
    width: auto;
    border: 1px solid #29928d;
    text-align: center;
    margin: 70px 0px 0px 0px;
}

.second-event{
    padding: 30px 0px;
    margin: 30px 0px 0px 0px;
}

.second-event .last{
    margin: 30px 110px 0px 0px;
    text-align: end;
}

@media screen and (max-width: 576px){
    .events-wrap h1{
        text-align: start;
        margin: 0px 0px 15px 0px;
        color: #1d6566;
        font-weight: bold;
        font-size: 1.8rem;
    }

    .events-title{
        color: #1d6566;
        letter-spacing: 0.1rem;
        margin-top: 10px;
    }

    .events-title h3{
        font-size: 1.3rem;
    }

    .events-title .events-data{
        text-align: center;
        /* background-color: chartreuse; */
        margin: 0px 10px 0px 0px;
        font-size: 0.9rem;
    }

    .events-img{
        margin: 15px 0px;
        padding: 0px 0px;
    }

    .events-wrap p{
        margin: 10px 30px;
        text-align: start;
        text-indent: 2rem;
        font-size: 1rem;
        letter-spacing: 0.2rem;
        color: #1d6566;
    }

    .second-event .last{
        margin: 30px 30px 0px 0px;
        text-align: end;
        letter-spacing: 0.1rem;
    }
}


/* Membership */
.membership-intro{
    color: #1d6566;
    padding: 10px 15px 10px 30px;
    margin: 38px 20px 20px 20px;
}

.membership-title{
    text-align: center;
    font-weight: bold;
    font-size: 2.3rem;
    color: white;
    background-color: #29928d;
    padding: 10px 0px 10px 0px;
    margin: 20px 160px 30px 170px;
}

.membership-intro-title{
    text-align: left;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 10px;
}

.membership-intro p{
    text-align: left;
    text-indent: 2rem;
    font-size: 1.3rem;
}

.membership-intro strong{
    color: #1d6566;
}

.NADSA{
    text-decoration: underline;
    color: #1d6566;
}

.hightline{
    background-color: #e4891b;
    border-radius: 20px;
    color: white;
    padding: 20px 0px 20px 0px;
    margin: 20px 160px 30px 170px;
}

.hightline strong{
    color: white;
    font-size: 1.5rem;
}

.hightline span{
    font-weight: bold;
    font-size: 1.5rem;
}

.membership-intro-little{
    padding: 0px 40px;
    margin-top: 10px;
}

.little-title{
    text-align: left;
    font-weight: bold;
    font-size: 1.5rem;
}

/* Benefits to Being a Member */
.membership-benefit{
    margin: 60px 0px;
    /* margin-bottom: 30px; */
}

/* membership-fee */
.membership-fee{
    background-color: #e4891b;
    height: auto;
    width: 800px;
    margin: 60px auto 20px auto;
    padding-bottom: 5px;
}

.membership-fee-title{
    text-align: center;
    font-weight: bold;
    font-size: 2.3rem;
    color: white;
    background-color: #e4891b;
    padding: 20px 20px 0px 20px;
    letter-spacing: 0.5rem;
    font-family: 'Roboto', sans-serif;
}

.membership-fee p{
    padding: 10px 30px;
    color: white;
    font-size: 1.3rem;
    text-indent: 0rem;
}

.membership-fee span{
    font-weight: bold;
    font-size: 1.6rem;
}

.notice{
    background-color: #f1f1f1;
    width: auto;
    padding: 4px 8px 20px 8px;
    margin: 20px 80px 5px 80px;
    border-radius: 20px;
}

.notice p{
    padding: 10px 5px;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 20px;
    text-align: center;
    color: #1d6566;
    margin: 0px 10px;
}

.notice-message-a{
    color: #1d6566;
    font-size: 1.5rem;
}

.notice-message-a:hover{
    font-size: 1.6rem;
    color: #1d6566;
}

.notice-border{
    width: 400px;
    border: 1px solid #1d6566;
    margin: 12px auto;
    margin-top: 18px;
}

.notice-link{
    color: #1d6566;
    font-size: 1.4rem;
}

.notice-link:hover{
    font-size: 1.5rem;
}

@media screen and (max-width: 768px){
    .membership-intro{
        color: #1d6566;
        padding: 15px 20px 0px 20px;
        margin: 0px auto;
    }

    .membership-title{
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        color: white;
        background-color: #29928d;
        margin: 20px auto 30px auto;    
    }

    .hightline{
        margin: 30px 20px;
        background-color: #e4891b;
        border-radius: 20px;
        padding: 10px 8px;
        color: white;
    }

    .membership-fee{
        width: auto;
        margin: 60px auto 20px auto;
        padding-bottom: 5px;
    }

    .notice{
        background-color: #f1f1f1;
        width: auto;
        padding: 0px 8px 20px 8px;
        margin: 20px 20px 5px 20px;
        border-radius: 20px;
        color: #1d6566;
    }

    .notice p{
        padding: 10px 5px;
        font-weight: bold;
        font-size: 1.5rem;
        border-radius: 20px;
        text-align: center;
        color: #1d6566;
        margin: 0px 10px;
    }
    
    .notice-message-a{
        text-decoration: underline;
        color: #1d6566;
        font-size: 1.3rem;
    }
    
    .notice-message-a:hover{
        font-size: 1.4rem;
        color: #1d6566;
    }
    
    .notice-border{
        width: auto;
        border: 1px solid #1d6566;
        margin: 12px 40px;
        margin-top: 18px;
    }
    
    .notice-link{
        color: #1d6566;
        text-decoration: underline;
        font-size: 1.1rem;
    }
    
    .notice-link:hover{
        color: #1d6566;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px){
    .membership-intro{
        color: #1d6566;
        padding: 10px 15px 0px 15px;
        margin: 0px auto;
    }

    .membership-title{
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        color: white;
        background-color: #29928d;
        margin: 20px auto 30px auto; 
        padding: 10px 10px;   
    }

    .membership-intro-title{
        text-align: left;
        font-weight: bold;
        font-size: 1.6rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .membership-intro p{
        text-align: left;
        text-indent: 2rem;
        font-size: 1.1rem;
    }

    .little-title{
        text-align: left;
        font-weight: bold;
        font-size: 1.4rem;
    }

    .hightline{
        margin: 30px 20px;
        background-color: #e4891b;
        border-radius: 20px;
        padding: 10px 8px;
        color: white;
    }

    .membership-fee{
        background-color: #e4891b;
        height: auto;
        width: auto;
        margin: 60px auto 20px auto;
        padding-bottom: 5px;
    }

    .membership-fee-title{
        text-align: center;
        font-weight: bold;
        font-size: 1.6rem;
        color: white;
        background-color: #e4891b;
        padding: 20px 4px 0px 4px;
        margin-top: -30px;
    }

    .membership-fee p{
        padding: 10px 14px;
        color: white;
        font-size: 1.2rem;
        text-indent: 0rem;
    }

    .membership-fee span{
        font-weight: bold;
        font-size: 1.4rem;
    }

    .notice{
        background-color: #f1f1f1;
        width: auto;
        padding: 0px 8px 20px 8px;
        margin: 20px 20px 5px 20px;
        border-radius: 20px;
        color: #1d6566;
    }
    
    .notice p{
        padding: 10px 5px;
        font-weight: bold;
        font-size: 1.3rem;
        border-radius: 20px;
        text-align: center;
        color: #1d6566;
        margin: 0px 10px;
    }
    
    .notice-message-a{
        text-decoration: underline;
        color: #1d6566;
        font-size: 1.2rem;
        text-align: center;
    }
    
    .notice-message-a:hover{
        font-size: 1.4rem;
        color: #1d6566;
    }
    
    .notice-border{
        width: auto;
        border: 1px solid #1d6566;
        margin: 12px 20px;
        margin-top: 18px;
    }
    
    .notice-link{
        color: #1d6566;
        text-decoration: underline;
        font-size: 1rem;
    }

    .notice-link:hover{
        color: #1d6566;
        font-size: 1.1rem;
    }
}

/* Contact Us */
.contactus-jumbo{
    background-image: url('https://aaadanyc.com/images/phone-3.1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 0px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    /* padding: 100px */
}

.contactus-jumbo-content{
    padding: 120px ;
}

.contactus-jumbo-content h2{
    font-weight: bold;
    font-size: 3rem;
    color: #1d6566;
    letter-spacing: 3px;
    text-align: center;
}

.contactus-jumbo-content p{
    font-weight: bold;
    font-size: 1.7rem;
    color: #1d6566;
    margin: 15px 120px 0px 120px;
    border-top: 1px solid #1d6566;
    padding-top: 20px;
}

.addresss-wrap{
    padding-top: 20px;
    margin-bottom: 10px;   
    color: white;
}

.addresss-wrap img{
    width: 800px;
    margin-top: 60px;
}

.address-intro{
    padding: 40px 0px;
}

.address-background{
    background-color: #29928d;
    border-radius: 20px;
    margin-top: 70px;
    padding-bottom: 10px;
    height: 380px;
}

.addresss-detail{
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.working-hours{
    font-size: 18px;
}

.working-hours span{
    font-size: 1.2rem;
}

.message-wrap{
    width: 100vw;
    margin-top: 40px;
}

.message-wrap .left{
    background-color:#29928d;
    width: 350px;
    height: 190px;
    margin: 160px 0px 0px 80px;
    padding: 20px 0px;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    padding: 20px 10px;
}

.message-wrap .left h4{
    padding-top: 20px;
}

.message-wrap .right{
    width: 500px;
    padding: 30px 0px;
    margin: 0px 0px 0px 230px;
    text-align: start;
    font-size: 1.2rem;
    color: #1d6566;
}

.message-wrap .right h3{
    font-size: 2rem;
    font-weight: bold;
}

.message-wrap .right form{
   margin-top: 20px; 
}

.message-wrap .right .btn{
    background-color: #29928d;
    border: none;
}

@media screen and (max-width: 768px){
    .message-wrap .left{
        width: 400px;
        margin: 50px 0px 0px 160px;
        font-size: 1.4rem;
    }

    .message-wrap .left h4{
        font-size: 1.9rem;
        margin-top: -8px;
    }

    .message-wrap .right{
        margin: 0px 0px 0px 40px ;
    }
}

@media screen and (max-width: 576px){
    .contactus-jumbo-content{
        margin: 0px 0px 0px -30px;
    }

    .contactus-jumbo-content h2{
        font-weight: bold;
        font-size: 1.8rem;
        color: #1d6566;
        text-align: center;
    }

    .contactus-jumbo-content .second{
        margin: 0px 0px 0px 14px;
        font-size: 1.8rem;
    }

    .contactus-jumbo-content p{
        margin: 20px 0px;
        font-size: 1.3rem;
    }

    .addresss-wrap img {
        margin: 10px 5px 20px 0px ;
    }

    .address-background {
        margin: 20px 30px 0px 30px ;
    }

    .addresss-detail .title{
        font-size: 1.4rem;
    }

    .addresss-detail span{
        font-size: 1rem;
    }

    .working-hours .title{
        font-size: 1.4rem; 
    }

    .working-hours span{
        font-size: 1rem;
    }

    .message-wrap .left {
        width: 220px;
        padding: auto 8px;
        height: auto;
        margin: 40px auto;
        font-size: 1.1rem;
    }
    .message-wrap .left h4{
        font-size: 1.3rem;
    }

    .message-wrap .right {
        width: auto;
        margin: 0px 15px;
    }

    .message-wrap .right h3{
        font-size: 1.8rem;
        font-weight: bold;
    }

    .message-wrap .form{
        font-size: 1rem;
    }
}

/* /////////////////////////////////// */

/* Online Form */
.application-ttile{
    background-color: #29928d;
    margin-top: 30px;
}

.application-ttile h1{
    text-align: center;
    padding: 20px 5px;
    color: white;
    font-weight: bold;
    letter-spacing: 0.05rem;
}

.intro-requirements{
    text-align: center;
    color: #1d6566;
    margin: 20px 30px 0px 30px;
    padding: auto 40px;
    font-size: 1.2rem;
}

.intro-requirements span{
    text-decoration: underline;
    font-weight: bold;
}

.intro-requirements strong{
    color: #1d6566;
}

.intro-requirements .refer{
    font-size: 1rem;
    margin: 20px 20px 0px 20px;
    padding: auto 20px;
}

.application-border{
    width: 800px;
    border: 1px solid #29928d;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
}

.application-small-title h2{
    color: #1d6566;
    margin: 60px 0px 30px 0px;
    font-weight: bold;
    letter-spacing: 0.2rem;
}

.application-small-title-two h2{
    color: #1d6566;
    margin: 0px 0px 40px 0px;
    font-weight: bold;
    letter-spacing: 0.2rem;
}

.form-wrap{
    font-size: 1.2rem;
    color: #1d6566;
}

.form-wrap .ADC{
    margin-bottom: 20px;
}

.attention{
    font-size: 1rem;
    background-color: #e4891b;
    text-align: center;
    color: white;
    padding: 5px 0px 5px 0px;
    height: 55px;
    width: 50px;
}

.payment-methods{
    background-color: #29928d;
    font-size: 1.1rem;
    color: white;
    margin: 50px auto 80px auto;
    padding: 10px 0px 1px 0px;
}

.payment-methods strong{
    color: white;

}

.payment-methods span{
    text-decoration: underline;
    font-weight: bold;
}

.law{
    background-color: #29928d;
    padding-left: 10px;
    margin-top: 20px;
}

.law p{
    font-size: 1.1rem;
    color: white;
    margin: 10px 20px;
    text-align: justify;
    text-indent: 2rem; 
}

.law .top{
    padding-top: 20px;
}

.law .bottom{
    padding-bottom: 20px;
}

.sing-date{
    margin-top: 40px;
    color: #1d6566;
    font-size: 1.4rem;
}

.to-left{
    text-align: left;
}

.application-btn{
    background-color: #29928d;
    margin: 40px 0px 15px 0px;
    font-size: 1.3rem;
}

@media screen and (max-width: 768px){
    .application-ttile h1{
        font-size: 2.2rem;
    }

    .application-border{
        max-width: 350px;
        border: 1px solid #29928d;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
    }

    .attention{
        padding: 10px 0px 0px 0px;
        height: auto;
        width: auto;
    }

    .law{
        margin: 20px 10px 0px 10px;
    }

    .sing-date .date{
        margin-top: 28px;
    }
}

@media screen and (max-width: 576px){
    .application-ttile h1{
        font-size: 1.7rem;
    }
    
    .intro-requirements{
        text-align: center;
        color: #1d6566;
        margin: 20px 20px 0px 20px;
        padding: auto 20px;
        font-size: 1.1rem;
    }

    .application-small-title h2{
        color: #1d6566;
        margin: 30px 0px 15px 0px;
        font-weight: bold;
        letter-spacing: 0rem;
        font-size: 1.4rem;
    }

    .application-small-title-two h2{
        color: #1d6566;
        margin: 30px 0px 15px 0px;
        font-weight: bold;
        letter-spacing: 0rem;
        font-size: 1.4rem;
    }

    .form-wrap{
        font-size: 1rem;
    }

    .application-border{
        max-width: 250px;
        border: 1px solid #29928d;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
    }

    .attention{
        font-size: 1rem;
        background-color: #e4891b;
        text-align: center;
        color: white;
        margin: 30px 15px 0px 15px;
        padding: 10px 0px 0px 0px;
        height: auto;
        width: auto;
    }

    .payment-methods{
        background-color: #29928d;
        font-size: 0.9rem;
        color: white;
        margin: 30px auto 20px auto;
        padding: 10px 10px 1px 10px;
    }

    .law p{
        font-size: 1rem;
        margin: 10px 5px;
        padding: 0px 5px;
        text-align: start;
        text-indent: 2rem;
    }

    .sing-date{
        margin-top: 30px;
        font-size: 1.2rem;
    }

    .sing-date .date{
        margin-top: 20px;
    }

    .application-btn{
        background-color: #29928d;
        margin: 25px 0px 5px 0px;
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 414px){
    .attention{
        font-size: 1rem;
        background-color: #e4891b;
        text-align: center;
        color: white;
        margin: 30px 15px 0px 15px;
        padding: 10px 60px 0px 60px;
    }
}

@media screen and (max-width: 375px){
    .attention{
        font-size: 1rem;
        background-color: #e4891b;
        text-align: center;
        color: white;
        margin: 30px 15px 0px 15px;
        padding: 10px 28px 0px 28px;
    }
}